(function () {
    'use strict';

    angular.module('informaApp')
        .component('barChartTable', {
            templateUrl: 'components/chart-view-profile/bar-chart-tabs-info/bar-chart-table/template.html',
            controller: [BarChartTableController],
            bindings: {
                source: '<'
            }
        });

    function BarChartTableController() {
        var vm = this;
    }
})();